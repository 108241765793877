import React from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Img from 'gatsby-image';

import { Section, Button, Title, Text, Box } from '../components/Core';
import PageWrapper from '../components/PageWrapper';
import Contact from '../sections/common/Contact';
import imgWorkCover from '../assets/image/jpg/details/details-1.jpg';
import imgS1 from '../assets/image/jpg/details/details-6.jpg';
import imgS2 from '../assets/image/jpg/details/details-5.jpg';
import imgS3 from '../assets/image/jpg/details/details-4.jpg';
import imgS4 from '../assets/image/jpg/details/details-3.jpg';
import imgS5 from '../assets/image/jpg/details/details-8.jpg';
import imgS6 from '../assets/image/jpg/details/details-7.jpg';

import { device } from '../utils';

export default ({ data }) => {
	const {
		seoTitle,
		projectTitle,
		clientName,
		keywords,
		bannerImage,
		bannerImageMobile,
		projectImages,
		node_locale,
	} = data.project;

	const sources = [
		bannerImageMobile.fluid,
		{
			...bannerImage.fluid,
			media: `(min-width: 576px)`,
		},
	];

	return (
		<>
			<PageWrapper>
				<Container
					fluid
					className="px-sm-5"
					css={`
						margin-top: 92px;
					`}
				>
					<Img
						fluid={sources}
						alt="banner"
						className="img img-fluid w-100"
						imgStyle={{ objectFit: 'cover', objectPosition: '50% 50%' }}
					/>
				</Container>
				<Section className="mt-lg-5">
					<Container>
						<Row>
							<Col lg="8">
								<Text variant="tag">
									{keywords.map((keyword, index) => (
										<span key={index}>{(index ? ', ' : '') + keyword}</span>
									))}
								</Text>
								<Title variant="secSm" className="my-4">
									{projectTitle}
								</Title>
								{/* <Text
									variant="p"
									css={`
										max-width: 750px;
									`}
								>
									{keywords}
								</Text> */}
							</Col>
						</Row>
					</Container>
				</Section>
				<div className="mt-lg-3">
					<Container>
						<Row>
							<Col lg="4" className="mb-4 mb-lg-0">
								<Text variant="tag">Naročnik</Text>
								<Title variant="cardBig" className="mt-3">
									{clientName}
								</Title>
							</Col>
						</Row>
					</Container>
				</div>
				<Section className="mt-lg-5">
					<Container>
						<Row>
							{projectImages &&
								projectImages.map((image) => (
									<Col xs="12" md="6" className="mb-5" key={image.id}>
										<Img
											fluid={image.fluid}
											alt="banner"
											className="img"
											imgStyle={{
												objectFit: 'cover',
												objectPosition: '50% 50%',
											}}
										/>
									</Col>
								))}
						</Row>
					</Container>
				</Section>
				<Box py={4}>
					<Contact />
				</Box>
			</PageWrapper>
		</>
	);
};

export const query = graphql`
	query($id: String!) {
		project: contentfulProjekt(contentful_id: { eq: $id }) {
			projectTitle
			clientName
			keywords
			bannerImage {
				fluid(quality: 80, maxWidth: 1400) {
					...GatsbyContentfulFluid
				}
			}
			bannerImageMobile {
				fluid(quality: 80, maxWidth: 700) {
					...GatsbyContentfulFluid
				}
			}
			projectImages {
				id: contentful_id
				fluid(quality: 80, maxWidth: 700) {
					...GatsbyContentfulFluid
				}
			}
		}
	}
`;
